<template>
  <div>
    <el-menu  class="el-menu-demo" mode="horizontal"  :router="true" default-active="/MyCenter/financeIndex/myApplyLog">
      <el-menu-item index="/MyCenter/financeIndex/myApplyLog">我的申请</el-menu-item>
    </el-menu>
    <router-view class="finance-wrapper"></router-view>
  </div>
</template>

<script>
export default {
  name: "Finance",
  data: function (){
    return{
      activeName: '',
    }
  },
  mounted() {
    this.$router.push("/MyCenter/financeIndex/myApplyLog")
  },
  methods: {
    handleRoute() {

    }
  }
}
</script>

<style scoped>
.finance-wrapper{
  margin: 20px;
}
</style>
